<template>
  <div>
     
        <h2 class="text-uppercase bottom20"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                      {{ $t('app.contact_us') }}
        </h2>

        <div class="row">
                  <div class="col-md-12 col-sm-4 bottom40">
                    <form @submit.prevent="sendMessage" class="callus">
                      <div class="form-group">
                        <input type="text" 
                            class="form-control" 
                            :placeholder="$t('app.name')"
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                            v-model="row.name">
                      </div>
                      <div class="form-group">
                        <!-- <input 
                            type="tel" 
                            class="form-control" 
                            :placeholder="$t('app.phone_number')"
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"> -->
                          <vue-tel-input 
                              class="form-control"
                              :inputOptions="options"
                              :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                              v-model="row.phone_number">
                          </vue-tel-input>
                      </div>
                      <div class="form-group">
                        <input 
                            type="email" 
                            class="form-control" 
                            :placeholder="$t('app.email_address')"
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                            v-model="row.email_address">
                      </div>
                      <div class="form-group">
                        <textarea 
                            class="form-control" 
                            :placeholder="$t('app.message')"
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                            v-model="row.message">
                        </textarea>
                      </div>

                    <button :disabled="btnLoading"
                            class="btn-blue uppercase border_radius"
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                            style="height: 50px">
                        <span v-if="btnLoading">
                            <div class="loaderN loaderN-md mauto mt-15" style='margin-left:-15px'></div>
                        </span>
                        <span v-if="!btnLoading">{{ $t('app.submit_now') }}</span>
                    </button>

                </form>
            </div>
        </div>

  </div>
</template>

<script>
import iziToast from 'izitoast'
import { VueTelInput } from 'vue-tel-input'

export default {
    name: 'ContactForm2',
    props: ['property_id'],
    components: {
        VueTelInput,
    },
    data(){
        return {
            //
            btnLoading: false,
            options: {
                placeholder: (this.$i18n.locale == 'ar') ? 'رقم الهاتف' : 'Phone Number'
            }, 
            row: {
                name: '',
                email_address: '',
                phone_number: '',
                message: '',
            },
        }
    },
        mounted() {},
    created() {},
    methods: {

        //
        sendMessage() {
            this.btnLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            const options = {
                url: window.baseURL+'/contacts',
                method: 'POST',
                data: {
                    property_id: this.property_id,
                    referral: this.$route.path,
                    name: this.row.name,
                    email_address: this.row.email_address,
                    phone_number: this.row.phone_number,
                    message: this.row.message
                },
            }
            this.axios(options)
            .then(() => {
                this.btnLoading = false;
                iziToast.success({
                    icon: 'ti-check',
                    title: 'Great job,',
                    message: 'Message Sent Successfully',
                });
                if (window.location.href.includes("landing")) {
                    this.$router.push({ path : '/landing/Thankyou' });
                } else {
                    this.$router.push({ path : '/Thankyou' });
                }
                
                this.row.message = '';
            })
            .catch(err => {
               
                this.btnLoading = false;
                iziToast.warning({
                    icon: 'ti-alert',
                    title: 'Wow-man,',
                    message: (err.response) ? err.response.data.message : 'Something went wrong'
                });

            })
            .finally(() => {});
        },
        

    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
